import request from 'utils/request';
const api = {
  HOLO_DATA_UPLOAD_PAGE: '/api/admin/holo/upload/result/page',
  HOLO_DATA_UPLOAD_DETAILS: '/api/admin/holo/upload/result/get',
  HOLO_DATA_UPLOAD_DEL: '/api/admin/holo/upload/result/delete',
  HOLO_DEVICE_NAME_UPDATE: '/api/admin/holo/upload/name/update',
};

// 上传记录 分页
export function fetchHoloDataUploadPage(query) {
  return request({
    url: api.HOLO_DATA_UPLOAD_PAGE,
    method: 'get',
    params: query,
  });
}

// 上传记录 详情
export function fetchHoloDataUploadDetails(query) {
  return request({
    url: api.HOLO_DATA_UPLOAD_DETAILS,
    method: 'get',
    params: query,
  });
}
// 上传记录 删除
export function fetchHoloDataUploadDel(query) {
  return request({
    url: api.HOLO_DATA_UPLOAD_DEL,
    method: 'get',
    params: query,
  });
}

export function updateDeviceNameAsyncInfo(query) {
  return request({
    url: api.HOLO_DEVICE_NAME_UPDATE,
    method: 'get',
    params: query,
  });
}
