import request from 'utils/request';
const api = {
  DMP_PROMOTION_APP_LIST: '/api/admin/dmp/app/list',
  DMP_APP_REPORT_QUERY: '/api/admin/dmp/report/app/contact/ratio',
  DMP_ADD_UPLOAD: '/api/admin/dmp/upload/task/add',
  DMP_GET_ADX_LIST: '/api/admin/dmp/adx/list',
  DMP_GET_ADX_LIST1: '/api/admin/dmp/adx/list1',
  HOLO_ADD_UPLOAD: '/api/admin/holo/upload/task/add',
  HOLO_ADD_SEARCH_COUNT: '/api/admin/holo/upload/task/searchCount',
};

// 列表
export function fetchPromotionAppList(query) {
  return request({
    url: api.DMP_PROMOTION_APP_LIST,
    method: 'get',
    params: query,
  });
}

export function fetchAppReportData(query) {
  return request({
    url: api.DMP_APP_REPORT_QUERY,
    method: 'get',
    params: query,
  });
}

export function addDmpUpload(data) {
  return request({
    url: api.DMP_ADD_UPLOAD,
    method: 'post',
    data,
  });
}
export function addHoloUpload(data) {
  return request({
    url: api.HOLO_ADD_UPLOAD,
    method: 'post',
    data,
  });
}

export function searchCount(data) {
  return request({
    url: api.HOLO_ADD_SEARCH_COUNT,
    method: 'post',
    data,
  });
}

export function fetchAdxList() {
  return request({
    url: api.DMP_GET_ADX_LIST,
    method: 'get',
  });
}

export function fetchAdxList1() {
  return request({
    url: api.DMP_GET_ADX_LIST1,
    method: 'get',
  });
}
